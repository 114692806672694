import React from "react";
import { db } from "../db/firebase-config";
import { collection, getDocs } from "firebase/firestore/lite";

const DataBaseView = () => {
  const [products, setProducts] = React.useState([]);
  // reference to products collection in database
  const productsCol = collection(db, "products");

  const getProducts = async (db) => {
    try {
      const data = await getDocs(productsCol);
      setProducts(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    } catch (err) {
      alert(err);
    }
  };

  React.useEffect(() => {
    getProducts(db);
  }, []);

  const liste = products.map((item, index) => {
    return (
      <div
        key={index}
        className='product-row'
      >
        <div className='product-name'>{item.name}</div>
        <div className='product-price'>{item.price}</div>
      </div>
    );
  });

  return (
    <div>
      <div>{liste}</div>
    </div>
  );
};

export default DataBaseView;
