import './App.css';
import DataBaseView from './components/DataBaseView';

function App() {
  return (
    <div className="App">
      noneGuru
      <DataBaseView />
    </div>
  );
}

export default App;
